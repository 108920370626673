import React, { useRef, useEffect } from "react"

import Layout from "../../components/layout"
import HeroTwo from "../../components/Hero/HeroTwo"
import Overview from "../../components/CaseStudy/Overview"
import { SEO } from "../../components/seo"
import TitleContent from "../../components/TitleContent/TitleContent"
import Hello from "../../components/Hello/Hello"
import Newsletter from "../../components/Form/Newsletter"
import ContentImage from "../../components/ContentImage/ContentImage"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import { caseStudies } from "."
import ShowcaseSlider from "../../components/ShowcaseSlider"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import StickySLide from "../../components/StickySlide"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/prismic.svg" },
    { src: "/case-study/tailwind.svg" }
]

const heroData = {
    preheader: "Unmind",
    title: "Empowering Growth and Flexibility: Website Transformation",
    fullWidth: true
}
const overview = {
    overview:
        "Unmind is a mental health platform designed to empower businesses and individuals by providing a suite of tools focused on mental well-being and resilience. Based in the UK, Unmind caters primarily to corporate clients, offering a scientifically-backed, digital solution for employee mental health. Their platform supports large organisations in fostering mental wellness, helping businesses provide their workforce with accessible, high-quality mental health resources and preventative care.",
    services: [
        "UI/UX",
        "Web Development",
        "SEO Optimisation",
        "CMS integration"
    ],
    linkUrl: "https://unmind.com/"
}
const lhScores = [
    {
        title: "Performance",
        score: 72
    },
    {
        title: "Accessibility",
        score: 98
    },
    {
        title: "Best Practices",
        score: 74
    },
    {
        title: "SEO",
        score: 92
    }
]
const Unmind = ({ location }) => {
    const context = {
        pageName: "SD | Case studies - Unmind",
        pageUri: location.href
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            <Overview {...overview} />
            <StaticImage
                src={"../../../static/case-study/unmind/Unmind-Top-banner.png"}
                alt="unmind top banner"
                objectFit="cover"
                className="mt-20 h-full w-full lg:mt-24"
            />
            <TitleContent
                title="Overcoming Website Bottlenecks: A Need for a Modern, Scalable CMS "
                className="py-14 lg:py-24 "
            >
                <p className="mb-16 lg:mb-20">
                    Unmind’s existing website on Webflow presented several
                    bottlenecks for their content and marketing teams, which
                    limited their flexibility and efficiency. Issues ranged from
                    slow backend loading and an inadequate page editor to
                    non-functioning plugins, especially affecting international
                    growth due to unreliable translation capabilities. Unmind
                    required a modern, flexible CMS with a more product-focused,
                    scalable structure to allow for ease of content creation and
                    fast loading times while maintaining a brand-aligned,
                    premium feel.
                </p>
                <h3 className="mt-6 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid max-h-[74px] min-h-[74px] place-items-center border px-6 py-2 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-cover grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>
            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimise User-Experience with Google Lighthouse"
                message={"If you want to get started manage with JAMstack, "}
                messageLinkTitle={
                    "check out our JAMstack Versus Traditional Website Development!"
                }
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    Unmind's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/Lighthouse-Unmind.pdf"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>
            <TitleContent title="The Challenge" className={"py-24 pt-28"}>
                <p>
                    {" "}
                    Unmind, a leader in digital mental health solutions, sought
                    a complete overhaul of their website to better reflect their
                    brand's premium, product-led identity.
                </p>
                <p className="mt-4">
                    Their previous Webflow setup posed challenges, including
                    slow backend loading, limited content management
                    flexibility, and an unreliable translation plugin that
                    restricted their global reach. Unmind’s content team
                    struggled with bottlenecks that prevented them from
                    effectively managing updates and new content, hindering the
                    platform's growth and user experience. They required a
                    modern, scalable solution that would empower their
                    non-technical teams to create and manage content seamlessly.
                </p>
            </TitleContent>

            <ShowcaseSlider
                slides={slides}
                className={" aspect-[1440/360] h-auto !min-h-0 w-full"}
            />

            <TitleContent title="The Approach" className={"my-28"}>
                <p className="text-base text-[#1D2333]">
                    Saigon Digital partnered with Unmind to migrate their
                    website to a more robust tech stack, integrating Prismic CMS
                    with its Slice Machine feature to give the content and
                    marketing teams full control over content creation. We
                    implemented Next.js for its performance and SEO
                    capabilities, complemented by Tailwind CSS for rapid,
                    consistent UI development.
                </p>
                <p className="mt-4">
                    This approach allowed Unmind to maintain a visually cohesive
                    brand identity, achieve faster load times, and boost search
                    engine visibility. We ensured the site was optimised for
                    Unmind’s global audience, enabling them to easily manage
                    multilingual content and provide a smooth user experience.
                </p>
            </TitleContent>
            <div className="container mb-24 grid gap-x-[30px] gap-y-10 md:grid-cols-2 lg:mb-24 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <img
                        src="/case-study/unmind/col-1.png"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <img
                        src="/case-study/unmind/col-2.png"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
            </div>
            <TitleContent title="The Solutions" className={"py-24 pt-28"}>
                <p>
                    {" "}
                    To meet Unmind’s needs, we leveraged the JAMstack
                    architecture, using Next.js for its SEO-friendly and
                    high-performance capabilities, and Prismic CMS to deliver a
                    flexible, user-friendly content management experience. Key
                    solutions included:
                </p>
                <ul className="ml-4 mt-2 list-disc">
                    <li>
                        <strong>Slice Machine Technology</strong>: Prismics
                        innovative feature has enabled Unmind's content team to
                        quickly build and manage custom pages without technical
                        assistance, drastically improving productivity.
                    </li>
                    <li>
                        <strong>Technical SEO and Redirects</strong>:
                        Comprehensive SEO improvements and redirect
                        implementations enhanced Unmind's search visibility and
                        preserved SEO value through the migration.
                    </li>
                    <li>
                        <strong>
                            Content Migration and Translation Support
                        </strong>
                        : We handled all content migration and implemented a
                        reliable translation solution, enabling Unmind to expand
                        their global reach effortlessly.
                    </li>
                </ul>
                <p className="mt-2">
                    This stack ensured that Unmind’s marketing team had the
                    tools to publish, edit, and manage content with ease while
                    maintaining the premium look and feel aligned with their
                    brand identity.
                </p>
            </TitleContent>
            <Newsletter />
            <TitleContent title="Outcomes" className="mt-20 lg:mt-28">
                <p>
                    Unmind's revamped website delivers a product-led,
                    user-friendly experience with a scalable CMS that empowers
                    their content team and strengthens brand engagement.
                    Enhanced performance and SEO practices have improved load
                    speed, search visibility, and global accessibility.
                </p>
            </TitleContent>

            <StickySLide>
                <ContentImage
                    className="lg:opacity-100"
                    img={"/case-study/unmind/outcome-1.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Empowering Unmind’s Brand
                    </h2>

                    <p>
                        Unmind’s website now embodies their product-led
                        approach, delivering a clean, user-friendly experience
                        that reflects their status as a leader in the mental
                        health space. With a flexible, scalable CMS, Unmind's
                        content team is empowered to create content and updates
                        without roadblocks. The enhanced design and improved
                        functionality strengthen Unmind's brand, fostering
                        better customer engagement and brand loyalty.
                    </p>
                </ContentImage>
                <ContentImage img={"/case-study/unmind/outcome-2.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Performance and SEO Gains
                    </h2>

                    <p className="text-[#1D2333]">
                        The combination of Next.js, Prismic CMS, and optimised
                        SEO practices has significantly boosted the website’s
                        load speed and search engine visibility. Server-side
                        rendering and static site generation have improved user
                        experience across all devices and languages, helping
                        Unmind to reach a broader audience effectively.
                    </p>
                </ContentImage>
                {/* <ContentImage img={"/case-study/ski/ski.com-Outcome.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Modern Tech Stack
                    </h2>

                    <p>
                        The migration to a modern tech stack positions Ski.com
                        ahead of competitors, with a site that can easily scale,
                        update, and deliver the best digital experiences for ski
                        vacation bookings.
                    </p>
                </ContentImage> */}
            </StickySLide>
            <TitleContent title={"The Results"} className={"mb-28"}>
                <p>
                    Our work with Unmind highlights the effectiveness of
                    JAMstack development in building a fast, flexible, and
                    scalable digital presence. The redesigned website supports
                    Unmind’s mission to provide accessible mental health
                    resources, positioning them for ongoing growth and increased
                    engagement. Through this collaboration, Unmind now has a
                    modern, content-driven platform that meets their operational
                    needs, enhances their user experience, and strengthens their
                    competitive positioning in the mental health sector.
                </p>
            </TitleContent>

            <div className="relative z-10 flex min-h-[40vh] flex-col items-center justify-center py-20 text-center  lg:py-20">
                <img
                    src="/case-study/unmind/Result.png"
                    className="absolute inset-0 z-0 h-[calc(100%+5px)] w-full object-cover"
                    alt="background"
                />
            </div>
            <div className=" mt-0">
                <StaticTestimonial
                    title={"Thoughts from Unmind.com"}
                    content={
                        <p>
                            Working with Saigon Digital on our website launch
                            was a fantastic experience. Their team brought our
                            vision to life with precision and creativity. They
                            were incredibly responsive to feedback, handling
                            every request and adjustment with a high level of
                            professionalism and expertise. Their collaborative
                            approach made the process smooth and efficient,
                            delivering a seamless user experience that perfectly
                            aligns with our objectives.
                        </p>
                    }
                    author={"George Stokes"}
                    position={"Digital Operations Lead"}
                />
            </div>
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

const slides = [
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/unmind/slide-1.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/unmind/slide-2.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/unmind/slide-3.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/unmind/slide-4.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    }
]

export default Unmind

export const Head = () => (
    <SEO
        title={"Unmind.com | Saigon Digital Case Study"}
        description={
            "Discover how Saigon Digital transformed Unmind’s website with scalable CMS, faster load speeds, and improved SEO to empower global mental health solutions."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
        image="/case-study/unmind/unmind-og.png"
    />
)
